import * as React from "react"
import Seo from "../components/seo"
import Container from "../components/Container"
import Gallery from "../components/Gallery"
import "twin.macro"

const GalleryPage = () => {
  return (
    <>
      <Seo title="Gallery" />
      <Container>
        <div tw="flex justify-center">
          <p
            tw="prose text-center font-display font-semibold text-yellow-700"
            style={{ textWrap: "balance" }}
          >
            I firmly believe that colors have the power to transform spaces,
            turning them into homes that are not just walls, but have a soul. My
            art is not just meant to be observed, but to be experienced – it
            invites you to dive in, to let go, and to discover new perspectives.
          </p>
        </div>
        <Gallery />
      </Container>
    </>
  )
}

export default GalleryPage
